export function useAuth() {
  const storeStore = useStoreStore()

  const redirectToLogin = (notEmbeddedAction: () => void) => {
    if (useEmbedded().isEmbedded.value) {
      const url = storeStore.store?.url
      const { companyId } = useStoreApp()
      if (storeStore.store?.provider === 1) {
        const { langCode, primaryLocale } = storeToRefs(useStoreStore())
        const urlStart = langCode.value === primaryLocale.value ? url : `${url}/${langCode.value}`

        // Special case for endoboutik
        if (companyId === 5375689) {
          navigateExternalUrl(`${url}/account/${langCode.value}/profile/informations`)
          return
        }
        navigateExternalUrl(`${urlStart}/account/login`)
      } else {
        navigateExternalUrl(`${url}/index.php?controller=my-account`)
      }

      return
    }
    notEmbeddedAction()
  }

  const redirectToRegister = (notEmbeddedAction: () => void) => {
    if (useEmbedded().isEmbedded.value) {
      const url = storeStore.store?.url
      const { companyId } = useStoreApp()

      if (storeStore.store?.provider === 1) {
        const { langCode, primaryLocale } = storeToRefs(useStoreStore())
        const urlStart = langCode.value === primaryLocale.value ? url : `${url}/${langCode.value}`

        // Special case for endoboutik
        if (companyId === 5375689) {
          navigateExternalUrl(`${url}/account/${langCode.value}/fr/profile/informations`)
          return
        }
        // legacy accounts still have register route, new doesn't single route for both
        if (storeStore.store?.customer_account_type === 1) {
          navigateExternalUrl(`${urlStart}/account/register`)
        } else {
          navigateExternalUrl(`${urlStart}/account/login`)
        }
      } else {
        navigateExternalUrl(`${url}/index.php?controller=my-account`)
      }

      return
    }
    notEmbeddedAction()
  }

  return {
    redirectToLogin,
    redirectToRegister
  }
}
