<script setup lang="ts">
  const { companyId } = useStoreApp()
  const { data: referralProgram } = useStoreReferral().getReferralProgram(companyId)
  const { data: creator } = useStoreCreator().fetchCreator()
  const { getMoneyFormattedText } = useFormattedText()

  const activeTab = ref('link')

  const props = defineProps<{
    isExtensionMode?: boolean
    isAccount?: boolean
  }>()

  const emit = defineEmits<{
    success: []
  }>()

  const { t, n } = useI18n()
  const { company } = useStoreCompany()

  const { extractButton } = useBrandingButton()

  const section = computed(() => {
    return props.isAccount ? sectionAccountReferral.value : sectionReferral.value
  })

  const sectionReferral = computed(() => {
    const { brandings } = useStoreBranding()
    const { extractTypography } = useBrandingTypography()

    if (!brandings?.branding_referral) return

    const { icon_email, icon_link, icon_sms } = brandings.branding_referral
    return {
      iconEmail: icon_email,
      iconLink: icon_link,
      iconSms: icon_sms,
      description: extractTypography('branding_referral', 'description', 'secondary'),
      button: extractButton('branding_referral', 'action_btn')
    }
  })

  const sectionAccountReferral = computed(() => {
    const { brandings } = useStoreBranding()
    const { extractTypography } = useBrandingTypography()

    if (!brandings?.branding_account_referral) return

    const { icon_email, icon_link, icon_sms } = brandings.branding_account_referral
    return {
      iconEmail: icon_email,
      iconLink: icon_link,
      iconSms: icon_sms,
      description: extractTypography('branding_account_referral', 'description', 'secondary'),
      button: extractButton('branding_account_referral', 'action_btn')
    }
  })

  const setActiveTab = (tab: string) => {
    activeTab.value = tab
  }

  const fullReferralLink = computed(() => `${referralProgram.value?.redirect_url}#ref=${creator.value?.id}`)

  const sendSms = () => {
    let trackKey = 'click-send-referral-sms'
    if (props.isAccount) {
      trackKey = 'click-referral-account-sms'
    } else if (props.isExtensionMode) {
      trackKey = 'click-send-referral-sms-page'
    }
    useTrack().track(trackKey)

    // reward types : 1 = points, 2 = code pourcentage, 3 = bon achat money
    let reward = ''
    switch (referralProgram.value!.godson_reward.type) {
      case 1:
        reward = t('SectionReferral.Tabs.reward1', { amount: n(referralProgram.value!.godson_reward.amount) })
        break
      case 2:
        reward = t('SectionReferral.Tabs.reward2', { amount: n(referralProgram.value!.godson_reward.amount) })
        break
      case 3:
        reward = t('SectionReferral.Tabs.reward3', {
          amount: getMoneyFormattedText(referralProgram.value!.godson_reward.amount)
        })
        break
    }
    const prefilledSms = encodeURI(
      t('SectionReferral.Tabs.smsTemplate', {
        brand_name: company!.name,
        referral_link: fullReferralLink.value,
        reward_friend: reward
      })
    )
    window.open(`sms:?&body=${prefilledSms.replace('#', '%23')}`)
  }

  const onCopied = async () => {
    let trackKey = 'click-referral-link-copy'
    if (props.isAccount) {
      trackKey = 'click-referral-account-link-copy'
    } else if (props.isExtensionMode) {
      trackKey = 'click-referral-link-copy-page'
    }
    useTrack().track(trackKey)
  }

  const isActiveTabLink = computed(() => activeTab.value === 'link')
  const isActiveTabEmail = computed(() => activeTab.value === 'email')
  const isActiveTabSms = computed(() => activeTab.value === 'sms')
</script>

<template>
  <div
    v-if="section"
    class="referral-tabs"
  >
    <div class="referral-tabs__header">
      <div
        class="referral-tabs__header--item"
        :class="{ 'referral-tabs__header--item--active': isActiveTabLink }"
        @click="setActiveTab('link')"
      >
        <img
          :src="section.iconLink"
          width="18"
          height="18"
        />
        {{ $t('SectionReferral.Tabs.link') }}
      </div>
      <div
        class="referral-tabs__header--item"
        :class="{ 'referral-tabs__header--item--active': isActiveTabEmail }"
        @click="setActiveTab('email')"
      >
        <img
          :src="section.iconEmail"
          width="18"
          height="18"
        />
        {{ $t('SectionReferral.Tabs.email') }}
      </div>
      <div
        v-if="isMobileAgent()"
        class="referral-tabs__header--item"
        :class="{ 'referral-tabs__header--item--active': isActiveTabSms }"
        @click="setActiveTab('sms')"
      >
        <img
          :src="section.iconSms"
          width="18"
          height="18"
        />
        {{ $t('SectionReferral.Tabs.sms') }}
      </div>
    </div>

    <div class="referral-tabs__content">
      <div
        v-if="isActiveTabLink"
        class="referral-tabs__content--link"
      >
        <FormInputText
          v-model="fullReferralLink"
          name="email"
          disabled
        />
        <ButtonCopy
          class="text-primary-heading-2"
          :radius="section.button.radius"
          :background-color="section.button.backgroundColor"
          :hover-background-color="section.button.hoverBackgroundColor"
          :font-size="section.button.fontSize"
          :text-color="section.button.textColor"
          :hover-text-color="section.button.hoverTextColor"
          :weight="section.button.weight"
          :copy-text="$t('SectionReferral.Tabs.copy')"
          :copied-text="$t('SectionRewardsClaimModal.copied')"
          :content-to-copy="fullReferralLink"
          @copy="onCopied"
        />
      </div>
      <div
        v-if="isActiveTabEmail"
        class="referral-tabs__content--email"
      >
        <SectionReferralForm
          :branding="section.button"
          :is-extension-mode="isExtensionMode"
          :is-account="isAccount"
          @success="emit('success')"
        />
      </div>
      <div
        v-if="isActiveTabSms"
        class="referral-tabs__content--sms"
      >
        <ButtonBranding
          :button-branding="section.button"
          type="submit"
          @click="sendSms"
        >
          {{ $t('SectionReferral.Tabs.smsSend') }}
        </ButtonBranding>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .referral-tabs {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__header {
      display: flex;
      justify-content: space-around;
      cursor: pointer;

      &--item {
        width: 100%;
        display: flex;
        height: 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #c4c4c4;
        color: v-bind(section.description.color);
        &--active {
          border-bottom: 2px solid #000;
        }
      }
    }

    &__content {
      @media (min-width: 768px) {
        margin-top: 20px;
      }

      :deep(.lds-form-input):disabled {
        cursor: initial;
        background-color: white;
      }

      &--link {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
</style>
