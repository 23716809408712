<script lang="ts" setup>
  onMounted(() => {
    document.querySelector('body')?.setAttribute('dir', 'auto')
  })
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
