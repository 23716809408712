import type { BrandingButtonElement, BrandingCategories, ButtonBranding } from '~/types/branding'

export function useBrandingButton() {
  const { brandings } = storeToRefs(useStoreBranding())

  const extractButton = (btnCategory: BrandingCategories, btnName: string): ButtonBranding => {
    if (!brandings.value)
      return {
        id: '',
        backgroundColor: '',
        hoverBackgroundColor: '',
        fontSize: 0,
        textColor: '',
        hoverTextColor: '',
        radius: 0,
        weight: 0,
        text: ''
      }

    const category = brandings.value[btnCategory]
    const element = category![btnName as keyof typeof category]

    const { id, background_color, hover_background_color, text_color, hover_text_color, size, radius, weight, text } =
      element as unknown as BrandingButtonElement

    return {
      id,
      backgroundColor: background_color,
      hoverBackgroundColor: hover_background_color,
      fontSize: size,
      textColor: text_color,
      hoverTextColor: hover_text_color,
      radius,
      weight: weight,
      text,
      align: 'left'
    } as ButtonBranding
  }

  return { extractButton }
}
